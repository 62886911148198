import { useState } from "react";
import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
} from "react-router-dom";
import { useAuth } from "../context";

export const LoginPage = () => {

    const [showPassword, setshowPassword] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    let from = location.state?.from?.pathname || "/";



    function handleSubmit(event) {
        event.preventDefault();

        let formData = new FormData(event.currentTarget);
        let username = formData.get("username");
        let password = formData.get("password");

        auth.signin(username, password, () => {
            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
            navigate(from, { replace: true });
        });
    }

    if (auth.user) {
        return <Navigate to={from ? from : '/'} replace />;
    }

    return (
        <div>
            {/* <p>You must log in to view the page at {from}</p> */}

            <form onSubmit={handleSubmit} className="login-form">
                <div className="form-field">
                    <label>Username</label>
                    <input type="text" name="username" id="username" required />
                </div>
                <div className="form-field">
                    <label>Password</label>
                    <div>
                        <input type={showPassword ? "text" : "password"} name="password" id="password" className="input-field" required />
                        <span className="icon-tail" onClick={() => setshowPassword(!showPassword)}>{showPassword ? <i class="fa-regular fa-eye"></i> : <i class="fa-regular fa-eye-slash"></i>}</span>
                    </div>
                </div>
                <div className="flex-d end">
                    <button type="submit" className="custom-btn">Login</button>
                </div>
            </form>
        </div>
    );
}