import { useEffect, useState } from 'react';
// import './App.css';
import db from '../firebase.config';
import { addDoc, collection, getDocs } from "firebase/firestore";

function ClientPage() {

    // const ref = collection(firestore, "student");

    const ref = collection(db, 'students');

    const class_ref = collection(db, 'classes');


    const initialState = {
        "name": "",
        "email": "",
        "passport_no": "",
        "address": "",
        "mobile_no": "",
        "esewa_code": "",
        "timetable": "-1",
        "errors": {
            "passport_no": false,
            "passport_msg": "Invalid Passport Message",
            "esewa_code": false,
            "esewa_msg": "Invalid Esewa ID",
            "timetable": false,
            "timetable_msg": "Please Select an Option"
        }
    }

    const [data, setData] = useState(initialState);

    const [isLoading, setIsLoading] = useState(false);

    const [classData, setClassData] = useState([]);


    const changeName = (event) => {
        setData({
            ...data,
            "name": event.target.value,
        })
    }

    const changeEmail = (event) => {
        setData({
            ...data,
            "email": event.target.value,
        })
    }

    const changePassport = (event) => {
        setData({
            ...data,
            "passport_no": event.target.value,
            errors: {
                ...data.errors,
                passport_no: false,
            }
        })
    }

    const changeAddress = (event) => {
        setData({
            ...data,
            "address": event.target.value,
        })
    }

    const changeMobileNo = (event) => {
        setData({
            ...data,
            "mobile_no": event.target.value,
        })
    }

    const changeEsewaCode = (event) => {
        setData({
            ...data,
            "esewa_code": event.target.value,
            errors: {
                ...data.errors,
                esewa_code: false,
            }
        })
    }


    const handleOnSubmit = async (event) => {

        event.preventDefault();
        setIsLoading(true);
        console.log("Submitted");

        const moment = require('moment-timezone');

        let uploadData = ({
            name: data.name.trim(),
            email: data.email.trim(),
            phone: data.mobile_no.trim(),
            address: data.address.trim(),
            passport: data.passport_no.trim(),
            esewa_code: data.esewa_code.trim(),
            time_table: data.timetable,
            created_at: moment.tz(new Date(), 'Asia/Kathmandu').format('YYYY-MM-DD hh:mm:ss A'),
        })


        if (uploadData.esewa_code.length < 7 || uploadData.esewa_code.length > 7) {
            setData({
                ...data,
                errors: {
                    ...data.errors,
                    esewa_code: true,
                    esewa_msg: "Invalid Esewa Code (It must have 7 digits)"
                }
            })

            setIsLoading(false);
            return;

        }

        if (uploadData.time_table === '-1') {
            setData({
                ...data,
                errors: {
                    ...data.errors,
                    timetable: true,
                    timetable_msg: "Please Select an Option"
                }
            })

            setIsLoading(false);
            return;
        }


        try {
            const notesSnapshot = await getDocs(ref);
            const studentsList = notesSnapshot.docs.map((doc) => doc.data());
            // console.log(studentsList)

            let prevPassport = studentsList.filter(function (fk) {
                return fk.passport === uploadData.passport
            })

            // console.log(prevPassport);


            let prevEsewa = studentsList.filter(function (fk) {
                return fk.esewa_code === uploadData.esewa_code
            })


            if (prevPassport.length > 0) {
                setData({
                    ...data,
                    errors: {
                        ...data.errors,
                        passport_no: true,
                        passport_msg: "This passport has been already registered"
                    }
                })

                setIsLoading(false);
            } else if (prevEsewa.length > 0) {
                setData({
                    ...data,
                    errors: {
                        ...data.errors,
                        esewa_code: true,
                        esewa_msg: "This Esewa Code has been already registered"
                    }
                })
                setIsLoading(false);

            } else {
                //uncomment this for last one
                await addDoc(ref, uploadData).then((docRef) => {
                    alert("Your Details have been registered");
                    setData(initialState)
                    setIsLoading(false);
                }).catch((error) => {
                    alert("Sorry for inconvenience please try again");
                    console.log(error);
                    setIsLoading(false);
                });
            }


        } catch (error) {
            console.log(error);
        }

    }


    const handleTimeTableChange = (event) => {
        setData({
            ...data,
            "timetable": event.target.value,
            errors: {
                ...data.errors,
                timetable: false,
            }
        })
    }


    const fetchClassData = async () => {

        console.log("What happened");

        try {
            const notesSnapshot = await getDocs(class_ref);
            const classList = notesSnapshot.docs.map((doc) => doc.data());

            // console.log("Nothing found");

            if (classList.length > 0) {
                setClassData(classList);
            } else {
                alert("No classes are available currently, 9803085094 मा सम्पर्क गर्नुहोला। ");
            }
        } catch (error) {
            console.log("Error found");
            alert("Error While Fetching classes details please try again later");
        }
    }



    useEffect(() => {
        fetchClassData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <div className="main-container">
            <div className="flex-d center">
                <img src="connect.svg" width="95%" alt="My Happy SVG" />
            </div>
            <div className="right-container">
                <div className="top-container">
                    <div className="title">
                        Kanchan Sir Ko A to Z Korean Language Institute
                    </div>
                    <div className="subtitle">
                        Class सम्बन्धी केही जानकारी लिनु परेमा 061585094 / 9803085094 मा सम्पर्क गर्नुहोला ।
                    </div>
                </div>
                <form onSubmit={handleOnSubmit}>
                    <div className="form-field">
                        <label>Full Name (Last Name + First Name + Middle Name)</label>
                        <input type="text" name="name" id="name" value={data.name} onChange={changeName} required />
                    </div>

                    <div className="row">
                        <div className="form-field-row">
                            <label>Email Address ( हजुरको email id छैन भने , 9803085094 मा सम्पर्क गर्नुहोला। )</label>
                            <input type="email" name="email" id="email" value={data.email} onChange={changeEmail} required />
                        </div>

                        <div className="form-field-row">
                            <label>Passport Number ( हालसालै e-passport लिएका व्यक्तिले alphabet सहितको number लेख्नुहोला। )</label>
                            <input type="text" name="passport" id="passport" className={data.errors.passport_no ? 'error_input' : ''} value={data.passport_no} onChange={changePassport} required />
                            {data.errors.passport_no ? <p className='error'>{data.errors.passport_msg}</p> : <></>}
                        </div>

                    </div>

                    <div className="form-field">
                        <label>Address (District)</label>
                        <input type="text" name="address" id="address" value={data.address} onChange={changeAddress} required />
                    </div>

                    <div className="row">
                        <div className="form-field-row">
                            <label>Mobile Number</label>
                            <input type="number" name="mobile_no" id="mobile_no" value={data.mobile_no} onChange={changeMobileNo} required />
                        </div>
                        <div className="form-field-row">
                            <label>Esewa Transaction Code</label>
                            <input type="text" name="esewa_code" id="esewa_code" className={data.errors.esewa_code ? 'error_input' : ''} value={data.esewa_code} onChange={changeEsewaCode} required />
                            {data.errors.esewa_code ? <p className='error'>{data.errors.esewa_msg}</p> : <></>}

                        </div>

                    </div>

                    <div className="form-field">
                        <label>Time for the class</label>
                        <select value={data.timetable} onChange={handleTimeTableChange} required>
                            <option value="-1">Select an Option</option>
                            {
                                classData.map((item) => <option value={item.id} key={item.name}>{item.name}</option>)
                            }
                        </select>
                        {data.errors.timetable ? <p className='error'>{data.errors.timetable_msg}</p> : <></>}

                    </div>
                    <div className="flex-d end">
                        <button type="submit" className="custom-btn" disabled={isLoading}>{isLoading ? "Submitting ..." : "Submit"}</button>
                    </div>
                </form>


            </div>
        </div>
    );
}

export default ClientPage;
