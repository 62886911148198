import * as React from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import AdminPage from "./pages/Admin";
import { fakeAuthProvider } from "./auth";
import ClassesPage from "./Classes";
import ClientPage from "./pages/ClientPage";
import { AuthContext, useAuth } from "./context";
import { LoginPage } from "./pages/Login";

import './App.css';
import AudioPage from "./pages/AudioPage";

export default function App() {

  return (
    <AuthProvider>

      <Layout />
      <Routes>
        <Route path="/" element={<ClientPage />} />
        <Route path="/resources" element={<AudioPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/admin"
          element={
            <RequireAuth>
              <AdminPage />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/classes"
          element={
            <RequireAuth>
              <ClassesPage />
            </RequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <div>
              404 Page not found
            </div>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

function Layout() {


  let auth = useAuth();
  let navigate = useNavigate();

  return (
    <div className="navbar">
      <Link className="logo" to="/">Hamro EPS</Link>
      <div className="flex-d">
        <Link className="router-link" to="/">Home</Link>

        <Link className="router-link" to="/resources">Resources</Link>
        <Link className="router-link" to="/admin">Admin</Link>


        {(auth.user) ? <Link className="router-link" to="/admin/classes">Classes</Link> : <></>}
        {(auth.user) ? <button
          className="btn"
          onClick={() => {
            auth.signout(() => navigate("/"));
          }}
        >
          Sign out
        </button> : <></>}
      </div>

      <Outlet />
    </div>
  );
}



function AuthProvider({ children }) {
  let [user, setUser] = React.useState(null);


  if (user == null && localStorage.getItem("user")) {

    if (localStorage.getItem("user").trim().toLowerCase() === 'epspkr') {
      setUser(localStorage.getItem("user"));
      fakeAuthProvider.loggedIn();
    }

  }

  let signin = (username, password, callback) => {
    return fakeAuthProvider.signin(() => {

      let pass = new Date();
      pass = pass.getFullYear() + "Pkr" + pass.getHours() + pass.getMinutes();

      if (username.trim().toLowerCase() === "epspkr" && password === pass) {
        localStorage.setItem("user", username);
        setUser(username);
        callback();
      } else {
        alert("Invalid Login Credentials");
      }


    });
  };

  let signout = (callback) => {
    return fakeAuthProvider.signout(() => {
      localStorage.removeItem("user");
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}



// Checking whether a protected route requires login or not.

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();


  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}


