import { useEffect, useState } from 'react';
// import './App.css';
import db from '../firebase.config';
import { doc, addDoc, collection, getDocs, getDoc } from "firebase/firestore";
import { CSVLink } from 'react-csv';
import ClassesPage from '../Classes';

function AdminPage() {

    // const ref = collection(firestore, "student");

    const ref = collection(db, 'students');

    const ref2 = collection(db, 'classes');

    const headers = [
        { label: "name", key: "name" },
        { label: "email", key: "email" },
        { label: "phone", key: "phone" },
        { label: "address", key: "address" },
        { label: "passport", key: "passport" },
        { label: "esewa_code", key: "esewa_code" },
        { label: "Class Time", key: "class_time" },
        { label: "created_at", key: "created_at" },

    ];

    const [studentData, setStudentData] = useState([]);
    const [classData, setClassData] = useState([]);


    const [search, setSearch] = useState('');
    const [FilteredStudentData, setFilteredStudentData] = useState([]);
    const [ascending, setAscending] = useState(true);


    const fetchClassList = async () => {
        try {

            const notesSnapshot2 = await getDocs(ref2);
            const calssList = notesSnapshot2.docs.map((doc) => doc.data());
            setClassData(calssList);

        } catch (error) {
            alert("Error While Fetching class data");
        }
    }


    const fetchData = async () => {
        try {
            const notesSnapshot = await getDocs(ref);
            let studentsList = notesSnapshot.docs.map((doc) => doc.data());



            studentsList = studentsList.map((item) => {

                // console.log("Snake");
                // console.log(classData.findIndex(classes => classes.id === item.time_table));


                let classFilter = classData.filter(classes => {
                    return classes.id.toString() === item.time_table.toString()
                })

                // console.log(classFilter.length > 0 ? "Found" : "Not Found")

                return ({
                    ...item,
                    "class_time": classFilter.length > 0 ? classFilter[0].name : "",
                });
            })


            setStudentData(studentsList);
            setFilteredStudentData(studentsList);

            setSearch('');
        } catch (error) {
            alert("Error While Fetching data");
        }
    }


    function sortByDate(a, b) {
        if (!ascending) {
            if (a.created_at < b.created_at) {
                return -1;
            }
            if (a.created_at > b.created_at) {
                return 1;
            }
            return 0;
        } else {
            if (a.created_at > b.created_at) {
                return -1;
            }
            if (a.created_at < b.created_at) {
                return 1;
            }
            return 0;
        }
    }


    useEffect(() => {
        FilteredStudentData.sort(sortByDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FilteredStudentData, ascending])


    const searchFilterFunction = (event) => {
        // alert("Optimal");

        let text = event.target.value;
        let textList = text.split(',');

        // console.log(text.split(','))

        if (text && studentData) {
            const newData = studentData.filter(
                function (item) {

                    const name = item.name
                        ? item.name.toUpperCase()
                        : ''.toUpperCase();


                    const address = item.address
                        ? item.address.toUpperCase()
                        : ''.toUpperCase();

                    const email = item.email
                        ? item.email.toUpperCase()
                        : ''.toUpperCase();

                    const passport = item.passport
                        ? item.passport.toUpperCase()
                        : ''.toUpperCase();


                    const esewa_code = item.esewa_code
                        ? item.esewa_code.toUpperCase()
                        : ''.toUpperCase();

                    const phone = item.phone
                        ? item.phone.toUpperCase()
                        : ''.toUpperCase();


                    for (let index = 0; index < textList.length; index++) {
                        const textData = textList[index].trim().toUpperCase();

                        let matches = name.includes(textData) || passport.includes(textData) || address.includes(textData) || email.includes(textData) || esewa_code.includes(textData) || phone.includes(textData);

                        if (matches) {

                            return matches;
                        }

                    }

                    // const textData = text.toUpperCase();

                    // return name.includes(textData) || passport.includes(textData) || address.includes(textData) || email.includes(textData) || esewa_code.includes(textData) || phone.includes(textData);

                });

            if (newData.length !== 0) {
                setFilteredStudentData(newData);
            } else {
                setFilteredStudentData([]);
            }
            setSearch(text);
        } else {
            setFilteredStudentData(studentData);
            setSearch(text);
        }
    };

    useEffect(() => {
        fetchData()
    }, [classData])

    useEffect(() => {
        fetchClassList()
    }, [])




    const csvReport = {
        data:
            FilteredStudentData
        ,
        headers: headers,
        filename: 'Student_Report.csv'
    };

    const handleSortChange = () => {
        setAscending(!ascending)
    }

    return (
        <div>
            <div className='topbar'>
                <input className='search-bar' placeholder='Search here ...' value={search} onChange={searchFilterFunction} />
                <div className='flex-d'>
                    <div className='sort-by-date' onClick={handleSortChange}>Sort by Date <span style={{ fontWeight: "700" }}>{ascending ? "↑" : "↓"}</span></div>

                    <CSVLink {...csvReport} className="custom-exporter">
                        Export Data
                    </CSVLink>

                </div>
            </div>
            <div className='custom_table'>
                <table className='custom_table'>
                    <thead>
                        <tr>
                            <th>S.N.</th>
                            <th>Name</th>
                            <th>Passport No</th>
                            <th>Address</th>
                            <th>Email Address</th>
                            <th>Phone No</th>
                            <th>Esewa Code</th>
                            <th>Class Time</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            FilteredStudentData.map((item, index) => {

                                console.log(classData.map((item) => item.id));
                                console.log(classData.filter((item2) => item2.id == item.time_table))

                                return (
                                    <tr key={item.passport}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.passport}</td>
                                        <td>{item.address}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.esewa_code}</td>
                                        <td>{item.class_time}</td>
                                        <td>{item.created_at}</td>
                                        {/* <td>{item.created_at ? `${item.created_at.split('T')[0]}  ${item.created_at.split('T')[1].split('.')[0]}` : ""}</td> */}
                                        <td>Edit/Delete</td>
                                    </tr>
                                );
                            })
                        }

                    </tbody>
                </table></div>
        </div>


    );
}

export default AdminPage;
