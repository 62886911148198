// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";

// import firebase from './firebase'
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCq4enampe5GV7waJErlve5MFn2PGu9FW8",
    authDomain: "hamro-eps.firebaseapp.com",
    projectId: "hamro-eps",
    storageBucket: "hamro-eps.appspot.com",
    messagingSenderId: "970833497353",
    appId: "1:970833497353:web:9644f3d9f164aa4fe417d8",
    measurementId: "G-FMMNHPJE6T"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

// const db = firebase.firestore();

// export default db;

// export const db = getFirestore(app);

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

export default db;