import { useEffect, useState } from 'react';
import './App.css';
import db from './firebase.config';
import { doc, addDoc, collection, getDocs, getDoc } from "firebase/firestore";
import { CSVLink } from 'react-csv';

function ClassesPage() {

    // const ref = collection(firestore, "student");

    const ref = collection(db, 'classes');

    const initialState = {
        'class': '',
        'error': false,
        'error_msg': "Invalid Class",
    };


    const headers = [
        { label: "name", key: "name" },
        { label: "created_at", key: "created_at" },

    ];

    const [classData, setClassData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const [search, setSearch] = useState('');
    const [FilteredClassesData, setFilteredClassesData] = useState([]);

    const [openModal, setOpenModal] = useState(false);


    const [ascending, setAscending] = useState(true);

    const [data, setData] = useState(initialState);

    const changeClass = (event) => {
        setData({
            ...data,
            "class": event.target.value,
            'error': false,
            'error_msg': "Invalid Class",
        })
    }




    const fetchData = async () => {
        try {
            const notesSnapshot = await getDocs(ref);
            const calssList = notesSnapshot.docs.map((doc) => doc.data());

            setClassData(calssList);
            setFilteredClassesData(calssList);
            setSearch('');
        } catch (error) {
            alert("Error While Fetching data");
        }
    }


    function sortByDate(a, b) {
        if (!ascending) {
            if (a.created_at < b.created_at) {
                return -1;
            }
            if (a.created_at > b.created_at) {
                return 1;
            }
            return 0;
        } else {
            if (a.created_at > b.created_at) {
                return -1;
            }
            if (a.created_at < b.created_at) {
                return 1;
            }
            return 0;
        }
    }


    useEffect(() => {
        FilteredClassesData.sort(sortByDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FilteredClassesData, ascending])


    const searchFilterFunction = (event) => {
        // alert("Optimal");

        let text = event.target.value;
        let textList = text.split(',');

        // console.log(text.split(','))

        if (text && classData) {
            const newData = classData.filter(
                function (item) {

                    const name = item.name
                        ? item.name.toUpperCase()
                        : ''.toUpperCase();



                    for (let index = 0; index < textList.length; index++) {
                        const textData = textList[index].trim().toUpperCase();

                        let matches = name.includes(textData);

                        if (matches) {

                            return matches;
                        }

                    }

                    // const textData = text.toUpperCase();

                    // return name.includes(textData) || passport.includes(textData) || address.includes(textData) || email.includes(textData) || esewa_code.includes(textData) || phone.includes(textData);

                });

            if (newData.length !== 0) {
                setFilteredClassesData(newData);
            } else {
                setFilteredClassesData([]);
            }
            setSearch(text);
        } else {
            setFilteredClassesData(classData);
            setSearch(text);
        }
    };

    useEffect(() => {
        fetchData()
    }, [])



    const csvReport = {
        data: FilteredClassesData,
        headers: headers,
        filename: 'Student_Report.csv'
    };

    // const handleSortChange = () => {
    //     setAscending(!ascending)
    // }


    const handleOnSubmit = async (event) => {

        event.preventDefault();
        setIsLoading(true);
        console.log("Submitted");

        const moment = require('moment-timezone');

        let uploadData = ({
            id: Date.now(),
            name: data.class.trim(),
            created_at: moment.tz(new Date(), 'Asia/Kathmandu').format('YYYY-MM-DD hh:mm:ss A'),
        })




        if (uploadData.name.length < 0) {
            setData({
                ...data,
                error: true,
                error_msg: "Invalid Class"
            })
            setIsLoading(false);
            return;
        }



        try {
            const notesSnapshot = await getDocs(ref);
            const classList = notesSnapshot.docs.map((doc) => doc.data());
            // console.log(studentsList)

            let prevClass = classList.filter(function (fk) {
                return fk.name === uploadData.name
            })



            if (prevClass.length > 0) {
                setData({
                    ...data,
                    error: true,
                    error_msg: "This Class has been already registered"
                })

                setIsLoading(false);
            } else {
                //uncomment this for last one
                await addDoc(ref, uploadData).then((docRef) => {
                    alert("Class has been registered");
                    setData(initialState);
                    setIsLoading(false);
                    setOpenModal(false);
                    fetchData();
                }).catch((error) => {
                    alert("Sorry for inconvenience please try again");
                    console.log(error);
                    setIsLoading(false);
                });
            }


        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }

    }

    return (
        <div>
            <div className='topbar'>
                <input className='search-bar' placeholder='Search here ...' value={search} onChange={searchFilterFunction} />
                <div className='flex-d'>
                    {/* <div className='sort-by-date' onClick={handleSortChange}>Sort by Date <span style={{ fontWeight: "700" }}>{ascending ? "↑" : "↓"}</span></div> */}

                    <button className='custom-exporter' onClick={() => setOpenModal(!openModal)}>
                        {openModal ? "Close" : "Add Class"}
                    </button>

                    <CSVLink {...csvReport} className="custom-exporter">
                        Export Data
                    </CSVLink>

                </div>
            </div>


            {
                openModal ? (<div className='class-adder'>
                    <form onSubmit={handleOnSubmit}>
                        <div className="form-field">
                            <label>Class Time table</label>
                            <input type="text" name="name" id="name" value={data.name} onChange={changeClass} required />
                        </div>

                        <div className="flex-d end">
                            <button type="submit" className="custom-btn" disabled={isLoading}>{isLoading ? "Adding ..." : "Add"}</button>
                        </div>
                    </form>
                </div>) : <></>
            }

            <div className='custom_table'>
                <table className='custom_table'>
                    <thead>
                        <tr>
                            <th>S.N.</th>
                            <th>Classes</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            FilteredClassesData.map((item, index) => {
                                return (
                                    <tr key={item.name}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.created_at}</td>

                                        <td>
                                            <span>Edit</span>
                                            <span>Delete</span>
                                        </td>
                                    </tr>
                                );
                            })
                        }

                    </tbody>
                </table></div>
        </div>


    );
}

export default ClassesPage;
