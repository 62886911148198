import React from 'react'
import ReactAudioPlayer from 'react-audio-player'




// import AudioTest from "../assets/1.m4a"

const AudioPage = () => {


    const audioResources = [
        {
            "id": 1,
            "name": "Audio 1 (01_250) 들은 것을 고르십시오.",
            "audio": "https://download-media.code-projects.org/2022/05/Audio_1_01_250.mp3"
        },
        {
            "id": 2,
            "name": "Audio 2 (01_150)그림에 알맞은 그림을 고르는 문제",
            "audio": "https://download-media.code-projects.org/2022/05/Audio_2_01_150.mp3"
        },
        {
            "id": 3,
            "name": "Audio 3 (151-300)그림에 알맞은 그림을 고르는 문제",
            "audio": "https://download-media.code-projects.org/2022/05/Audio_3_151-300.mp3"
        },
        {
            "id": 4,
            "name": "Audio 4 ( 01_170) 알맞은 대답을 고르는 문제",
            "audio": "https://download-media.code-projects.org/2022/05/Audio_4__01_170.mp3"
        },
        {
            "id": 5,
            "name": "Audio 5 (171_340) 알맞은 대답을 고르는 문제",
            "audio": "https://download-media.code-projects.org/2022/05/Audio_5_171_340.mp3"
        },
        {
            "id": 6,
            "name": "Audio 6 (01_135) 이어지는 말을 고르는 문제",
            "audio": "https://download-media.code-projects.org/2022/05/Audio_6_01_135.mp3"
        },
        {
            "id": 7,
            "name": "Audio 7 (1_135) 들은 내용과 관계 있는 그림을 고르는 문제",
            "audio": "https://download-media.code-projects.org/2022/05/Audio_7_1_135.mp3"
        },
        {
            "id": 8,
            "name": "Audio 8 ( 01_175) 이야기를 듣고 알맞은 대답을 고르는 문제",
            "audio": "https://download-media.code-projects.org/2022/05/Audio_8__01_175.mp3"
        },
        {
            "id": 9,
            "name": "Audio 9 ( 176_350) 이야기를 듣고 알맞은 대답을 고르는 문제",
            "audio": "https://download-media.code-projects.org/2022/05/Audio_9__176_350.mp3"
        },
        {
            "id": 10,
            "name": "Just For TestSound",
            "audio": "https://download-media.code-projects.org/2022/05/justfortest.mp3"
        },

    ]

    return (
        <div style={{ width: '96vw', margin: '0 auto' }}>
            <h1>Audio Book</h1>

            <h4>All the Audio book</h4>

            <div className='audioshelf'>
                {
                    audioResources.map((item, index) => {
                        return (
                            <div key={item.id} className="audio-card">
                                <h4>{item.name}</h4>
                                <ReactAudioPlayer
                                    style={{ minWidth: '90%' }}
                                    src={item.audio}
                                    controls
                                />
                            </div>
                        )
                    })
                }

            </div>





            {/* <AudioPlayer
                autoPlay
                src={TestSound}
                onPlay={e => console.log("onPlay")}
            // other props here
            /> */}
        </div >
    )
}

export default AudioPage
