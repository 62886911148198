/**
 * This represents some generic auth provider API, like Firebase.
 */

const fakeAuthProvider = {
    isAuthenticated: false,

    loggedIn() {
        fakeAuthProvider.isAuthenticated = true;
    },
    signin(callback) {
        console.log(callback);
        fakeAuthProvider.isAuthenticated = true;
        setTimeout(callback, 100); // fake async
    },
    signout(callback) {
        fakeAuthProvider.isAuthenticated = false;
        setTimeout(callback, 100);
    },
};

export { fakeAuthProvider };
